import styled from 'styled-components';

import { font } from '../../../styles/atoms/typography';
import { colors } from '../../../styles/atoms/colors';
import { atMinWidth } from '../../../styles/atoms/breakpoints';

export const Header = styled.header`
max-width: 770px;
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
gap: 32px;
margin: 0px auto;
.heading-kicker {
  ${font('overline', 'sm', '700')}
  color: ${colors.primary[500]};
}
.heading {
  ${font('display', 'md', '700')}
  color: ${colors.gray[900]};
  ${atMinWidth.sm`
      ${font('display', 'lg', '700')}
  `}
  ${atMinWidth.lg`
      ${font('display', 'xl', '700')}
  `}
}
.subhead, .subhead p {
  ${font('text', 'md', '400')}
  color: ${colors.gray[600]};
  ${atMinWidth.sm`
      ${font('text', 'lg', '400')}
  `}
  ${atMinWidth.lg`
      ${font('text', 'xl', '400')}
  `}
}
`;
