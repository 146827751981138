import React from 'react';
import CallToAction from '../../../molecules/CallToAction/CallToAction';
import { getTag } from '../../../utils/getTag';
import { Header } from '../styles/CardDeckHeader.styled';

const CardDeckHeader = ({
  heading,
  headingKicker,
  headingKickerTag,
  headingTag,
  link,
  subhead,
}) => {
  const Heading = headingTag ? getTag(headingTag) : 'h2';
  const Kicker = headingKickerTag ? getTag(headingKickerTag) : 'p';

  return headingKicker || heading || subhead || link ? (
    <Header>
      {headingKicker && (
        <Kicker className="heading-kicker">{headingKicker}</Kicker>
      )}
      {heading && <Heading className="heading">{heading}</Heading>}
      {subhead && (
        <div
          dangerouslySetInnerHTML={{ __html: subhead }}
          className="subhead"
        />
      )}
      {link?.url && (
        <CallToAction variant="primary" link={link?.url} value={link?.label} />
      )}
    </Header>
  ) : (
    <></>
  );
};

export default CardDeckHeader;
